const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  token: state => state.user.token,
  factoryList: state => state.user.factoryList,
  name: state => state.user.name,
  roles: state => state.user.roles,
  devicedata: state => state.user.devicedata,
  permission_routers: state => state.permission.routers,
  permissions: state => state.permission.permissions,
  userDevice: state => state.permission.userDevice,
}
export default getters
