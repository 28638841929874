import { getToken } from '@/utils/auth';

const user = {
  state: {
    token: '',
    name: '',
    factoryList: '',
    roles: [],
    devicedata: {},
  },

  mutations: {
    SET_DEVICEDATA: (state, data) => {
      state.devicedata = data
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_FACTORYLIST: (state, factoryList) => {
      state.factoryList = factoryList
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },

  actions: {
    // 设置token
    setTokenVuex({ commit }) {
      commit('SET_TOKEN', getToken())
    },
  }
}

export default user
