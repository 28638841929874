import { constantRouterMap } from "@/router";
import { getUserInfoApi } from "@/api/user";
import { Message } from "element-ui";

const permission = {
  state: {
    routers: constantRouterMap,
    permissions: [],
    userDevice: null,
  },
  mutations: {
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_USER_DEVICE: (state, userDevice) => {
      state.userDevice = userDevice;
    },
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfoApi().then((response) => {
          if (response.data.code != 0) {
            Message({
              message: response.data.msg || `后端接口${code}异常`,
              type: "error",
              duration: 30 * 1000,
            });
            return;
          }
          commit("SET_PERMISSIONS", response.data.user.permissions);
          commit("SET_TOKEN", response.data.user.token);
          commit("SET_FACTORYLIST", response.data.user.factoryList);
          resolve(response.data.user);
        });
      });
    },
    SetUserDevice({ commit }, userDevice) {
      commit("SET_USER_DEVICE", userDevice);
    },
  },
};

/**
 * 路由懒加载
 */
export const loadView = (view) => {
  // webpack4中动态import不支持变量方式
  return (resolve, reject) => require([`@/views/${view}`], resolve);
};

export default permission;

// {
//   path: '/example',
//   component: Layout,
//   redirect: '/example/table',
//   name: 'Example',
//   meta: { title: 'Example', icon: 'example' },
//   children: [
//     {
//       path: 'table',
//       name: 'Table',
//       component: () => import('@/views/table/index'),
//       meta: { title: 'Table', icon: 'table' }
//     },
//     {
//       path: 'tree',
//       name: 'Tree',
//       component: () => import('@/views/tree/index'),
//       meta: { title: 'Tree', icon: 'tree' }
//     }
//   ]
// },
// {
//   path: '/icon',
//   component: Layout,
//   children: [{
//     path: 'index',
//     component: () => import('@/views/svg-icons/index'),
//     name: 'icons',
//     meta: { title: 'icons', icon: 'icon', noCache: true }
//   }]
// }
