import request from "@/utils/request";

const saasUrl = '/tiger-admin/sys/user'


// 获取用户信息
export function getUserInfoApi() {
  return request({
    url: "/api/user/getInfo",
    method: "get",
  });
}

export function getUsernames() {
  return request({
    url: `${saasUrl}/getUsernames`,
    method: 'get'
  })
}
