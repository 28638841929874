import { getQueryString } from "@/utils/index";

const TokenKey = "Yitu_Weigh_Token";

export function getToken() {
  let token = getQueryString(location.href, 'token');
  if (!token) {
    token = localStorage.getItem(TokenKey);
  }
  setToken(token);
  return token;
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}
export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
