import Vue from 'vue'

import 'normalize.css/normalize.css'// A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/element-#7073FF/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

import animate from 'animate.css'

import '@/styles/index.scss' // global css
import '@/utils/dialog.js'
import App from './App'
import router from './router'
import store from './store'
import i18n from "./i18n";
import {
  localizeAsset,
  localizeParam,
  localizeReturnValue
} from './util/i18n/general'

Vue.mixin({
  methods: {
    localizeParam: function (item, param) {
      return localizeParam(item, param)
    },
    localizeAsset: function (assetName) {
      return localizeAsset(assetName)
    },
    localizeReturnValue: function (data) {
      return localizeReturnValue(data)
    }
  }
})

import '@/icons' // icon
import '@/permission' // permission control
Vue.use(ElementUI, { locale })

Vue.use(animate)

import Print from '@/api/print'
Vue.use(Print) // 注册

import axios from 'axios'
Vue.prototype.$http=axios

Vue.config.productionTip = false

Vue.prototype.isAuth = (key) => {
  return (store.getters.permissions || '[]').indexOf(key) !== -1 || false
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
